import React, { useState, useEffect } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import ThumbnailDeletedImage from "../assets/deleted.png"; // 削除された動画用のサムネイル画像
import { API } from "@aws-amplify/api";
import * as queries from "../graphql/queries";

function Playlist({ getUserApiResult }) {
  const [playListData, setPlaylistData] = useState({}); // プレイリストデータの状態管理
  const [playListItemData, setPlaylistItemData] = useState([]); // プレイリスト内のアイテムデータ
  const [videoByIdListData, setVideoByIdListData] = useState([]); // 動画IDリストに基づいた動画データ
  const { id } = useParams(); // URLパラメータからプレイリストIDを取得
  const idNumber = parseInt(id, 10);
  const [notFound, setNotFound] = useState(false); // プレイリストが見つからなかった場合の状態管理
  const navigate = useNavigate();

  // プレイリストデータを取得する
  useEffect(() => {
    const fetchData = async () => {
      try {
        const playlistResponse = await API.graphql({
          query: queries.getPlaylist,
          variables: { id: idNumber },
        });

        // プレイリストが存在しない場合、notFound状態をtrueに設定
        if (!playlistResponse?.data?.getPlaylist?.data) {
          setNotFound(true);
          return;
        }

        // プレイリストデータを状態にセット
        setPlaylistData(playlistResponse.data.getPlaylist);
      } catch (error) {
        console.error("Error:", error);
        setNotFound(true); // エラー時も、notFound状態をtrueに設定
      }
    };

    // プレイリスト内のアイテムデータを取得する
    const fetchItemData = async () => {
      try {
        const playlistItemResponse = await API.graphql({
          query: queries.getPlaylistItem,
          variables: { playlist_id: idNumber },
        });

        setPlaylistItemData(playlistItemResponse.data.getPlaylistItem.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData(); // プレイリストデータ取得
    fetchItemData(); // プレイリストアイテムデータ取得
  }, [idNumber]); // idNumberに変更があった際に再取得

  // プレイリストに紐付いた動画データを取得する
  useEffect(() => {
    const fetchVideoByIdListData = async (playListData) => {
      if (playListData?.data?.playlist_videos?.length > 0) {
        try {
          const variables = { idList: playListData.data.playlist_videos };
          const userVideoByIdListResponse = await API.graphql({
            query: queries.getVideoByIdList,
            variables: variables,
          });
          const getUserVideoByIdList =
            userVideoByIdListResponse.data.getVideoByIdList.video_list;
          setVideoByIdListData(getUserVideoByIdList); // 動画データを状態にセット
        } catch (error) {
          console.error("Error fetching video data:", error);
        }
      }
    };

    if (playListData) {
      fetchVideoByIdListData(playListData);
    }
  }, [playListData]); // プレイリストデータが更新された際に実行

  // プレイリストが見つからなかった場合、notfoundページに遷移
  useEffect(() => {
    if (notFound) {
      navigate(`/notfound`, { replace: true });
    }
  }, [notFound, navigate]);

  // 動画カードをクリックした際の動作: 動画再生ページへ遷移
  const handleCardClick = (id) => {
    navigate(`/playmovie/${id}`);
  };

  // プレイリストアイテムのカスタム説明をマッピングする
  const playlistItemMap = playListItemData.reduce((map, item) => {
    map[item.video_id] = item.custom_description;
    return map;
  }, {});

  return (
    <Box>
      {/* プレイリストのタイトル・説明文 */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{ marginBottom: 2 }} // タイトルと説明文の間にマージンを追加
      >
        {playListData?.data?.title}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginLeft: "40px" }}>
        {playListData?.data?.description}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Grid container spacing={2}>
        {videoByIdListData.map((video) => (
          <Grid item xs={12} key={video.id}>
            <Card
              onClick={() => handleCardClick(video.id)}
              sx={{ cursor: "pointer", display: "flex", flexDirection: "row" }}
            >
              {/* 動画のサムネイル */}
              <CardMedia
                component="img"
                sx={{ width: 345, height: 200, objectFit: "cover" }}
                image={
                  video.status === "DELETED"
                    ? ThumbnailDeletedImage // 削除された動画のサムネイル
                    : video.setting_thumbnail_url || video.thumbnail_url
                }
                alt={`Thumbnail for video ${video.id}`}
              />

              <Box sx={{ flex: 1 }}>
                {/* 動画タイトル・説明文*/}
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "1.5rem", // タイトルのフォントサイズを少し大きくする
                      lineHeight: "1.4", // 行間を少し縮める
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      minHeight: "2.8em",
                      fontWeight: "bold",
                      marginBottom: 3, // タイトルと説明文の間にマージンを追加
                    }}
                  >
                    {video.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3, // 3行に制限
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      lineHeight: "1.5", // 行間を1.5に設定（調整可能）
                      marginLeft: "20px",
                      bgcolor: "grey.200",
                      borderRadius: "8px",
                      padding: "8px",
                    }}
                  >
                    {
                      playlistItemMap.hasOwnProperty(video.id)
                        ? playlistItemMap[video.id] // カスタム説明がある場合は表示
                        : video.description // なければ通常の説明を表示
                    }
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Playlist;
