/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const countVideo = /* GraphQL */ `
  mutation CountVideo($id: Int!, $register_date: String!) {
    countVideo(id: $id, register_date: $register_date) {
      resultCode
      __typename
    }
  }
`;
export const countUpLike = /* GraphQL */ `
  mutation CountUpLike($id: Int!, $email: String!, $register_date: String!) {
    countUpLike(id: $id, email: $email, register_date: $register_date) {
      resultCode
      __typename
    }
  }
`;
export const registerUser = /* GraphQL */ `
  mutation RegisterUser($email: String!) {
    registerUser(email: $email) {
      userData {
        email
        bookmarked_videoid_list
        follow_list
        follower_list
        is_admin
        user_name
        profile_picture
        profile_text
        registered_videoid_list
        liked_videoid_list
        __typename
      }
      resultCode
      __typename
    }
  }
`;
export const registerViewHistory = /* GraphQL */ `
  mutation RegisterViewHistory($input: ViewHitoryInput!) {
    registerViewHistory(input: $input) {
      resultCode
      __typename
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo($id: Int!, $register_date: String!) {
    deleteVideo(id: $id, register_date: $register_date) {
      resultCode
      __typename
    }
  }
`;
export const registerVideo = /* GraphQL */ `
  mutation RegisterVideo($registerVideoItem: RegisterVideoItem) {
    registerVideo(registerVideoItem: $registerVideoItem) {
      resultCode
      videoUrl
      thumbnailUrl
      video_id
      register_date
      __typename
    }
  }
`;
