import { useState } from "react";
import axios from "axios";

import { checkFileName } from "../variables/ManageRelated.js";

import CommonForm from "../components/CommonForm.js";

//import API_ADD from "../config.js";
import ProgressBarDialog from "../components/ProgressBarDialog.js";

import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";

function Register({ getUserApiResult }) {
  // プログレスバーの表示関数
  const [videoId, setVideoId] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (inputData) => {
    // Step1. DBに登録
    const data = {
      videoTitle: inputData.title,
      videoTag: inputData.tag,
      videoSubTag: inputData.subTags.split(",").map((tag) => tag.trim()), // AppSync側は配列なのでそのまま渡す
      description: inputData.descriptions,
      videoFile: checkFileName(inputData.video[0].name),
    };

    if (inputData.thumbnail.length > 0) {
      data.thumbnailFile = checkFileName(inputData.thumbnail[0].name);
    }

    let videoUrl = "";
    let thumbnailUrl = "";
    let videoId = "";

    try {
      // AppSyncのmutationをAPI.graphqlで呼び出す
      const response = await API.graphql({
        query: mutations.registerVideo,
        variables: {
          registerVideoItem: data, // 送信するデータを変数として渡す
        },
      });

      const result = response.data.registerVideo;

      if (result.resultCode === 2000) {
        // 成功時の処理
        videoUrl = result.videoUrl;
        thumbnailUrl = result.thumbnailUrl;
        videoId = result.video_id;
        setVideoId(videoId);
      } else {
        console.error("ビデオ登録APIに失敗しました");
      }
    } catch (error) {
      console.error("AppSync APIとの接続に失敗しました", error);
    }
    const [baseUrl, queryParams] = videoUrl.split("?");
    const fullUrl = `${baseUrl}?${queryParams}`;
    // Step2. プログレスバーの表示
    handleClickOpen();

    // Step3. S3にアップロード 動画
    try {
      const videoHeaders = {
        "Content-Type": "video/mp4",
      };

      const videoUploadResponse = await axios.put(fullUrl, inputData.video[0], {
        headers: videoHeaders,
      });

      if (videoUploadResponse.status === 200) {
        console.log("動画のアップロードに成功しました");
      }
    } catch (error) {
      console.error(`動画のアップロードに失敗しました, error=${error}`);
    }

    // Step4. S3にアップロード サムネイル
    if (inputData.thumbnail.length > 0) {
      try {
        const thumbnailHeaders = {
          "Content-Type": "upload/input/image/*",
        };

        const thumbnailUploadResponse = await axios.put(
          thumbnailUrl,
          inputData.thumbnail[0],
          { headers: thumbnailHeaders },
        );

        if (thumbnailUploadResponse.status === 200) {
          console.log("サムネイル画像のアップロードを完了しました");
        }
      } catch (error) {
        console.error(
          `サムネイル画像のアップロードに失敗しました, error=${error}`,
        );
      }
    }
  };

  // 初期値設定
  const initVal = {
    title: "",
    tag: "",
    subtags: [],
    description: [],
    setting_thumbnail_url: "",
    video_url: "",
  };

  return (
    <>
      <CommonForm
        onSubmit={onSubmit}
        initVal={initVal}
        state="regist"
        getUserApiResult={getUserApiResult}
      />
      <ProgressBarDialog
        open={open}
        handleClose={handleClose}
        videoId={videoId}
      />
    </>
  );
}

export default Register;
